import {
	IonBackButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonGrid, IonHeader, IonIcon, IonPage,
	IonRow, IonText, IonToolbar
} from '@ionic/react';
import { call, mail } from 'ionicons/icons';
import React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import './Support.css';

type Props = {
	history: any,
}

type State = {
}

class Support extends React.Component<Props, State> {

	constructor(props: any) {
		super(props);
		this.state = {
		}
	}
	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton mode='md' defaultHref='/' />
						</IonButtons>
						{/* <IonButtons slot='start'>
								<IonMenuButton/>
						</IonButtons> */}

						<IonCardTitle><Trans>Assistenza</Trans></IonCardTitle>
					</IonToolbar>
				</IonHeader>


				<IonContent fullscreen>
					<IonGrid className='supportGrid'>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle><Trans>Assistenza</Trans></IonCardTitle>
							</IonCardHeader>

							<IonCardContent>
								<IonGrid className='profileSupportGrid'>
									{/* <IonRow
										onClick={() => { window.open('tel:+393661126799') }}
										className='supportRow'
									>
										<IonIcon
											icon={call}
											size='large'
											className='supportIcon'
										/>
										<IonText className='supportLabel'>
											<Trans>Telefono</Trans>
										</IonText>
									</IonRow> */}

									<IonRow
										onClick={() => { window.open('mailto:support@reasyedu.com'); }}
										className='supportRow'
									>
										<IonIcon
											icon={mail}
											size='large'
											className='supportIcon'
										/>
										<IonText className='supportLabel'>
											<Trans>Email</Trans>
										</IonText>
									</IonRow>
								</IonGrid>
							</IonCardContent>
						</IonCard>
					</IonGrid>



				</IonContent>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {

	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Support);